import React from 'react'
import './watchlist.css'
import { useState } from 'react';

const Watchlist = () => {
  const [stocks] = useState([
    {
      name: 'APPLE INC',
      ticker: 'NASDAQ',
      price: 147.95,
      change: -110.60,
      percentChange: -30.52,
    },
    {
      name: 'TESLA INC',
      ticker: 'NASDAQ',
      price: 680.35,
      change: 50.10,
      percentChange: 5.52,
    },
    {
      name: 'MICROSOFT CORP',
      ticker: 'NASDAQ',
      price: 299.00,
      change: 12.15,
      percentChange: 4.24,
    },
    {
      name: 'AMAZON.COM INC',
      ticker: 'NASDAQ',
      price: 3450.00,
      change: -45.50,
      percentChange: -1.30,
    },
    {
      name: 'FACEBOOK INC',
      ticker: 'NASDAQ',
      price: 355.64,
      change: 25.60,
      percentChange: 7.76,
    },
    {
      name: 'GOOGLE LLC',
      ticker: 'NASDAQ',
      price: 2738.27,
      change: -13.47,
      percentChange: -0.49,
    }
  ]);
  

  return (
    <div className="app watchlist-box">
      <div className="search-container">
        <input type="text" placeholder="Search Stock" className="search-input" />
      </div>
      <div className="watchlist-container">
        <div className="watchlist-header">
          <div className="watchlist-title">My Watchlist <span className="watchlist-count">15</span></div>
          <div className="dropdown">
            <button className="dropbtn">My Watchlist</button>
          </div>
        </div>
        <div className="watchlist">
          {stocks.map((stock, index) => (
            <div key={index} className="stock-item">
              <div className="stock-info">
                <div className="stock-name">{stock.name}</div>
                <div className="stock-ticker">{stock.ticker}</div>
              </div>
              <div className="stock-price">
                <div className={`price ${stock.change < 0 ? 'down' : 'up'}`}>
                  ${stock.price.toFixed(2)}
                </div>
                <div className={`change ${stock.change < 0 ? 'down' : 'up'}`}>
                  {stock.change.toFixed(2)} ({stock.percentChange.toFixed(2)}%)
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Watchlist