import React, { useState } from 'react';
// import { IoMdGrid, IoNotifications } from 'react-icons/io';
import { IoMdGrid } from "react-icons/io";
import { IoNotifications } from "react-icons/io5";
import { CgProfile } from "react-icons/cg";
import './NewPortfolio.css';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import Topgainer from './topgainer';
import Toplosers from './toplosers';
import Watchlist from './watchlist';

// Register the components with ChartJS
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const NewPortfolio = () => {
    const [isOpen, setIsOpen] = useState(false);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };
    const data = {
        labels: [
            'Mar \'12', 'Apr \'12', 'May \'12', 'Jun \'12', 'Jul \'12', 'Aug \'12', 'Sep \'12', 'Oct \'12', 'Nov \'12', 'Dec \'12', 'Jan \'13', 'Feb \'13'
        ],
        datasets: [
            {
                label: 'Nifty 50',
                data: [32.0, 33.0, 31.5, 30.0, 31.0, 29.0, 28.5, 30.5, 32.0, 33.5, 34.5, 36.0],
                fill: true,
                backgroundColor: 'rgba(76, 175, 80, 0.2)',
                borderColor: 'rgba(76, 175, 80, 1)',
                tension: 0.1
            }
        ]
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                mode: 'index',
                intersect: false,
            },
        },
        scales: {
            x: {
                type: 'category',
                title: {
                    display: false,
                },
            },
            y: {
                beginAtZero: false,
                title: {
                    display: false,
                },
                ticks: {
                    callback: function (value) {
                        return value.toFixed(2);
                    }
                }
            }
        }
    };


    return (
        <>
            <nav className="navbar bg-white shadow-md p-4 md:p-6 lg:px-20 flex justify-between items-center">
                <div className="flex items-center justify-between w-full md:w-auto">
                    <div className="flex items-center">
                        <img src="logo.png" alt="Logo" className="h-6 mr-2" /> {/* Replace with your logo image */}
                        <span className="text-xl font-semibold">Bullkit</span>
                    </div>
                    <button className="md:hidden text-2xl ml-auto" onClick={handleToggle}>
                        ☰
                    </button>
                </div>
                <ul className={`nav-links flex-col md:flex-row md:flex items-center ${isOpen ? 'flex' : 'hidden'} md:flex`}>
                    <li className="md:ml-6 my-2 md:my-0">
                        <a href="/dashboards" className="text-gray-700">Dashboards</a>
                    </li>
                    <li className="md:ml-6 my-2 md:my-0">
                        <a href="/market" className="text-gray-700">Market</a>
                    </li>
                    <li className="md:ml-6 my-2 md:my-0">
                        <a href="/portfolio" className="text-gray-700">Portfolio</a>
                    </li>
                    <li className="md:ml-6 my-2 md:my-0">
                        <a href="/position" className="text-gray-700">Position</a>
                    </li>
                    <li className="md:ml-6 my-2 md:my-0 relative">
                        <a href="/orders" className="text-gray-700">Orders</a>
                        {/* <span className="notification-dot absolute top-0 right-0 bg-red-600 w-2.5 h-2.5 rounded-full"></span> */}
                    </li>
                    <li className="md:ml-6 my-2 md:my-0">
                        <a href="/funds" className="text-gray-700">Funds</a>
                    </li>
                </ul>
                <div className="navbar-right flex items-center ml-auto md:ml-0">
                    <div className="icon mr-4">
                        <IoMdGrid className="text-2xl md:text-4xl border rounded-full p-1 bg-gray-400" />
                    </div>
                    <div className="icon mr-4 relative">
                        <IoNotifications className="text-2xl md:text-4xl border rounded-full p-1 bg-gray-400" />
                    </div>
                    <div className="profile flex items-center">
                        <CgProfile className="text-2xl md:text-4xl border rounded-full p-1 bg-gray-400" />
                        <span className="admin font-semibold ml-2">Admin</span>
                    </div>
                </div>
            </nav>

            <div className="ticker-nav ">
                <div className="ticker">
                    <div className="index">
                        <p className="index-name">Nifty 50</p>
                        <span className="index-value">16,538.45</span>
                        <span className="index-change ">-78.00 (0.49%)</span>
                    </div>
                    <div className="index">
                        <p className="index-name">BSE Sensex</p>
                        <span className="index-value">57,578.76</span>
                        <span className="index-change ">-273.51 (0.52%)</span>
                    </div>
                </div>
                <div className="nav">
                    <ul className="nav-links">
                        <li><a href="/explore">Explore</a></li>
                        <li><a href="/investment">Investment</a></li>
                        <li><a href="/ipo">IPO</a></li>
                    </ul>
                </div>
            </div>

            <div className='row-b-x-traing'>
                <div className="">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="row">
                                <div className="col-lg-3 col-md-4 col-sm-12">
                                <div className='fund-box'>
    <div className="row fund-box-row">
        <div className="col-8">
            <h2>Fund Available</h2>
            <h2>$2400.00</h2>
            <button className="btn btn-primary d-flex justify-center">Add Funds</button>
        </div>
        <div className="col-4">
            <img className="money-icon" src="https://png.pngtree.com/png-vector/20191128/ourmid/pngtree-coin-money-icon-png-image_2049478.jpg" alt="Money Icon" />
        </div>
    </div>
</div>

                                    <div className='New-ipo-box'>
                                        <div className="nav-ul">
                                            <ul className='ul-item'>
                                                <li>New Ipo</li>
                                                <li>3</li>
                                            </ul>
                                        </div>
                                        <hr />
                                        <div className='ipo-logo-box'>
                                            <div className='ipo-logo-box-img'>
                                                <img src="https://i.pinimg.com/736x/6e/36/f2/6e36f2c9d9cc523f5904d6e4c91921f4.jpg" alt="" />
                                            </div>
                                            <h2 className='font-semibold'>McDonald's</h2>
                                            <p className='text-sm mt-2'>Sep 06, 2021 to Sep 10, 2021</p>
                                            <p className='text-xs mt-2'>Allotment : Sep 14, 2021</p>
                                            <button className='mt-2'>Apply</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-9 col-md-8 col-sm-12">
                                    <div className='nifty-50-box'>
                                        <h2>Nifty 50</h2>
                                        <Line data={data} options={options} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                    <Topgainer />
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <Toplosers />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12">
                            <Watchlist />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default NewPortfolio;
