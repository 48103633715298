import React from "react";
import './AboutTest.css'
import { AiOutlineGlobal } from "react-icons/ai";
import { FaSearchDollar } from "react-icons/fa";
import { BsFillFileBarGraphFill } from "react-icons/bs";
import { RiCustomerService2Fill } from "react-icons/ri";

const AboutTest = () => {
    return (
        <>
            {/* banner-part */}
            <div className="relative w-full h-40 md:h-80 lg:h-50"> {/* Adjusted height */}
                <img src="https://wallpapers.com/images/hd/trading-wallpaper-ynfqhj74ml8p96ca.jpg" alt="Banner" className="object-cover w-full h-full md:h-96 lg:h-96" />
                {/*banner-content */}
                <div className="absolute top-0 left-5 lg:left-5 right-0 bottom-0 text-white flex flex-col justify-center ">
                    <div className="container mx-auto">
                        <h1 className="text-lg md:text-7xl font-semibold text-gray-100">Our Company</h1>

                        {/* <p className="mt-4 text-md md:text-xl font-semibold text-gray-100">Start Investing and Trading with Trading E’s Steps</p> */}
                        <ul class="d-flex mt-3">
                            <li class="text-xl font-bold" style={{ color: '#49cacd' }}>
                                <a href="#">HOME</a>
                            </li>
                            <p class="mx-4 text-xl font-bold" style={{ color: 'gold' }}>/</p>
                            <li class="mx-2 text-xl font-bold" style={{ color: '#49cacd' }}>
                                <a href="#">ABOUT US</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>


            {/* services cards */}

            <section class=" mx-auto px-4 sm:px-6 lg:px-8 flex flex-wrap items-center justify-between who-we-are-box">
                <div class="w-full sm:w-1/2  sm:text-left">
                    <div class="text-box">

                        <h1 class="text-2xl sm:text-3xl lg:text-xl font-bold who-we-h1">
                            <span>|</span> WHO WE ARE
                        </h1> <br />
                        <h1 class="text-3xl sm:text-4xl lg:text-5xl font-bold what-isgettrade">What is <span>GetTrade</span>?</h1>
                        <p class="text-base sm:text-lg lg:text-xl para-p1">Malesuada vulputate pharetra natoque morbi id ex nec eleifend neque interdum. Cras vel scelerisque dis nisi magnis phasellus tempor. Nostra lobortis sagittis nulla finibus metus.</p>
                        <p class="text-base sm:text-lg lg:text-xl para-p2">Dis laoreet pharetra fusce feugiat est habitant diam eros senectus rhoncus. Ad suscipit curabitur primis taciti dolor.</p>
                        <button class="btn btn-Discover-more bg-teal-500 hover:bg-teal-600 text-white font-bold py-2 px-4 rounded mt-4 sm:mt-6">Discover More</button>
                    </div>
                </div>
                <div class="w-full sm:w-1/2 mt-6 sm:mt-0">
                    <img class="image-lapi w-full h-auto" src="https://kitnew.moxcreative.com/gettrade/wp-content/uploads/sites/8/2022/11/Ilus-3.png" alt="" />
                </div>
            </section>

            {/* services cards */}

            {/* card section */}
            <div className='card-container-box'>
                <div className='card-box grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4'>
                    <div className="col-md-3">
                        <div className="card shadow first-card mx-auto" style={{ width: '18rem', height: '17rem' }}>
                            <div className="card-body">
                                <h5 className="card-title card-title-1"><AiOutlineGlobal /></h5>
                                <h6 className="card-subtitle mt-2 card-head-i">Wide Product Range</h6>
                                <p className="card-text mt-2">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="card shadow second-card mx-auto" style={{ width: '18rem', height: '17rem' }}>
                            <div className="card-body">
                                <h5 className="card-title card-title-2"><FaSearchDollar /></h5>
                                <h6 className="card-subtitle card-head mt-2">Transparent Pricing</h6>
                                <p className="card-text card-text-2 mt-2">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="card shadow second-card mx-auto" style={{ width: '18rem', height: '17rem' }}>
                            <div className="card-body">
                                <h5 className="card-title card-title-2"><BsFillFileBarGraphFill /></h5>
                                <h6 className="card-subtitle mt-2 card-head">Innovative <br /> Tools</h6>
                                <p className="card-text card-text-2 mt-2">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="card shadow second-card mx-auto" style={{ width: '18rem', height: '17rem' }}>
                            <div className="card-body">
                                <h5 className="card-title card-title-2"><RiCustomerService2Fill /> </h5>
                                <h6 className="card-subtitle mt-2 card-head">Dedicated Support</h6>
                                <p className="card-text card-text-2 mt-2">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* card section */}

            {/* Reliable company */}
            <section className='investments_that_Box-1'>
                <div class="flex flex-col sm:flex-row">
                    <div class="sm:w-full md:w-1/2 md:pr-8">
                    <h1 class="text-3xl sm:text-4xl lg:text-5xl font-bold h2-heading mt-4">
                        Origins
                        </h1>
                        <p className="mt-4 consecteture ">Consectetuer egestas eget eleifend ut <br /> faucibus risus per.</p>
                        <h1 class="text-2xl sm:text-3xl lg:text-xl font-bold who-we-h1-new">
                            <span>|</span> Environmental care is the most  profitable investment.
                        </h1>
                        
                    </div>
                    <div class=" sm:w-full md:w-1/2 md:pl-8 mt-8 sm:mt-0">
                        <p class="text-base sm:text-lg lg:text-base p-tag-color">
                            Vehicula si vel phasellus iaculis interdum ex sociosqu id. Ullamcorper habitasse litora pharetra dictum mauris at vestibulum conubia placerat. Nostra odio fames sollicitudin nisl nulla fringilla enim. Inceptos felis maximus euismod venenatis purus mattis dignissim enim. 
                        </p>
                        <p class="text-base sm:text-lg lg:text-base mt-4 p-tag-color">
                            Viverra bibendum platea maximus conubia dictumst lorem hendrerit euismod. Nam pretium litora vestibulum amet magna ridiculus inceptos. Interdum erat fames arcu sodales volutpat nisl mollis tincidunt pellentesque porttitor. Sociosqu venenatis suspendisse nibh eros imperdiet scelerisque. 
                        </p>
                        <p class="text-base sm:text-lg lg:text-base mt-4 p-tag-color">Class hendrerit eros potenti proin ornare nec blandit. Libero magnis class viverra consectetur turpis sed dui vivamus et. Penatibus taciti tempus sed torquent blandit maximus feugiat. Laoreet euismod eros senectus penatibus fames sollicitudin cursus mattis bibendum finibus tortor. Malesuada sem amet nisl </p> <br />
                        <button className="btn more-about-us">More About Us</button>
                    </div>
                </div>
            </section>
            {/* Reliable company */}

            {/* get Trade start */}
            <section class="GetTrade-Section_box bg-white py-12">
        <div class="get-trade-sub-box w-full md:w-3/4 lg:w-2/3 xl:w-1/2 mx-auto p-4 ">
          <h1 class="text-3xl md:text-4xl lg:text-5xl font-bold mb-4">
            <span class="text-teal-500">GetTrade</span> is trusted by 6+ million <br class="hidden md:block" /> users in 175+ countries
          </h1>
          <p class="text-base md:text-lg">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
          </p>

          <div class="testimonial-carousel mt-8 grid grid-cols-2 md:grid-cols-4 gap-4">
            <img src="https://kitnew.moxcreative.com/gettrade/wp-content/uploads/sites/8/2022/11/logoipsum-285.png" alt="" class="max-w-xs max-h-xs" />
            <img src="https://kitnew.moxcreative.com/gettrade/wp-content/uploads/sites/8/2022/11/logoipsum-264.png" alt="" class="max-w-xs max-h-xs" />
            <img src="https://kitnew.moxcreative.com/gettrade/wp-content/uploads/sites/8/2022/11/logoipsum-289.png" alt="" class="max-w-xs max-h-xs" />
            <img src="https://kitnew.moxcreative.com/gettrade/wp-content/uploads/sites/8/2022/11/logoipsum-236-1.png" alt="" class="max-w-xs max-h-xs" />
          </div>
        </div>
      </section>
            {/* get Trade end */}

        {/* Why choose us */}
        <section class="why-choose-us-box mt-10 md:mt-20 lg:mt-32">
        <div class="container mx-auto flex flex-col-reverse md:flex-row">
          <div class="md:w-1/2">
            <img class="w-full rounded-lg" src="https://media.istockphoto.com/id/1327069216/photo/decision-making-is-better-when-you-do-it-together.jpg?s=612x612&w=0&k=20&c=4D5etc3VrGI_CrjrZsm3k4Re0cNgUAKywcy-UPA3g6g=" alt="Decision Making Together" />
          </div>
          <div class="md:w-1/2 md:ml-10">
            <h1 class="text-xl md:text-2xl lg:text-xl font-semibold text-teal-400 mb-4">| WHY CHOOSE US</h1>
            <h2 class="text-2xl md:text-3xl font-bold mb-4">An investment that takes you to great heights</h2>
            <p class="mb-6">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.</p>
            <div class="flex mb-4">
              <div class="w-12 h-12 flex items-center justify-center rounded-md p-2 bg-teal-400 mr-4">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-white" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M16.293 3.293a1 1 0 0 1 1.414 1.414l-9 9a1 1 0 0 1-1.414 0l-5-5a1 1 0 1 1 1.414-1.414L7 11.086l8.293-8.293a1 1 0 0 1 1.414 0z" clip-rule="evenodd" />
                </svg>
              </div>
              <div>
                <h3 class="text-lg md:text-xl font-semibold mb-1">Trusted by Investor</h3>
                <p class="mb-4">Suscipit class etiam letius efficitur a hac accumsan si. Lacinia arcu dictumst a penatibus non fermentum donec sociosqu sed vulputate ante.</p>
              </div>
            </div>
            <div class="flex mb-4">
              <div class="w-12 h-12 flex items-center justify-center rounded-md p-2 bg-teal-400 mr-4">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-white" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M16.293 3.293a1 1 0 0 1 1.414 1.414l-9 9a1 1 0 0 1-1.414 0l-5-5a1 1 0 1 1 1.414-1.414L7 11.086l8.293-8.293a1 1 0 0 1 1.414 0z" clip-rule="evenodd" />
                </svg>
              </div>
              <div>
                <h3 class="text-lg md:text-xl font-semibold mb-1">Invest With Confidence</h3>
                <p class="mb-4">Suscipit class etiam letius efficitur a hac accumsan si. Lacinia arcu dictumst a penatibus non fermentum donec sociosqu sed vulputate ante.</p>
              </div>
            </div>
            <div class="flex">
              <div class="w-12 h-12 flex items-center justify-center rounded-md p-2 bg-teal-400 mr-4">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-white" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M16.293 3.293a1 1 0 0 1 1.414 1.414l-9 9a1 1 0 0 1-1.414 0l-5-5a1 1 0 1 1 1.414-1.414L7 11.086l8.293-8.293a1 1 0 0 1 1.414 0z" clip-rule="evenodd" />
                </svg>
              </div>
              <div>
                <h3 class="text-lg md:text-xl font-semibold mb-1">Secure Platform</h3>
                <p class="mb-4">Suscipit class etiam letius efficitur a hac accumsan si. Lacinia arcu dictumst a penatibus non fermentum donec sociosqu sed vulputate ante.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
        {/* Why choose us */}

        {/* Our blog section */}
        <section className='our-blog-container'>
            <div className="col-lg-8 col-md-10 col-12 mx-auto blog-clm-B my-3">
              <h1 className="blog-clm-B-h1"> <span>|</span> FROM OUR BLOG</h1>
              <h1 className='blog-clm-B-h2'>Latest Article</h1>

              <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
                <div className="d-flex gap-3">
                  <div className="image-box-div">
                    <img src="https://media.istockphoto.com/id/1297832726/photo/portrait-of-a-smiling-young-businessman.jpg?s=612x612&w=0&k=20&c=32Qg7TnqfGkrDwTL3q0X0Kx9ab3JDzuqxzp4poH39zc=" alt="" />
                  </div>
                  <div className="col-7 inner-col_B">
                    <p className='p1'>Who In Their Right Mind Would Even Facebook’s New Libra Coin?</p>
                    <p className='p2'>October 31, 2022  No Comments</p>
                    <p className='p3'>
                      <a href="#">Read More »</a>
                    </p>
                  </div>
                </div>
                <div className="d-flex gap-3">
                  <div className="image-box-div">
                    <img src="https://media.istockphoto.com/id/1297832726/photo/portrait-of-a-smiling-young-businessman.jpg?s=612x612&w=0&k=20&c=32Qg7TnqfGkrDwTL3q0X0Kx9ab3JDzuqxzp4poH39zc=" alt="" />
                  </div>
                  <div className="col-7 inner-col_B">
                    <p className='p1'>Who In Their Right Mind Would Even Facebook’s New Libra Coin?</p>
                    <p className='p2'>October 31, 2022  No Comments</p>
                    <p className='p3'>
                      <a href="#">Read More »</a>
                    </p>
                  </div>
                </div>
                <div className="d-flex gap-3">
                  <div className="image-box-div">
                    <img src="https://media.istockphoto.com/id/1297832726/photo/portrait-of-a-smiling-young-businessman.jpg?s=612x612&w=0&k=20&c=32Qg7TnqfGkrDwTL3q0X0Kx9ab3JDzuqxzp4poH39zc=" alt="" />
                  </div>
                  <div className="col-7 inner-col_B">
                    <p className='p1'>Who In Their Right Mind Would Even Facebook’s New Libra Coin?</p>
                    <p className='p2'>October 31, 2022  No Comments</p>
                    <p className='p3'>
                      <a href="#">Read More »</a>
                    </p>
                  </div>
                </div>
                <div className="d-flex gap-3">
                  <div className="image-box-div">
                    <img src="https://media.istockphoto.com/id/1297832726/photo/portrait-of-a-smiling-young-businessman.jpg?s=612x612&w=0&k=20&c=32Qg7TnqfGkrDwTL3q0X0Kx9ab3JDzuqxzp4poH39zc=" alt="" />
                  </div>
                  <div className="col-7 inner-col_B">
                    <p className='p1'>Who In Their Right Mind Would Even Facebook’s New Libra Coin?</p>
                    <p className='p2'>October 31, 2022  No Comments</p>
                    <p className='p3'>
                      <a href="#">Read More »</a>
                    </p>
                  </div>
                </div>
                {/* Repeat similar structure for other blog posts */}
              </div>
            </div>
       
    </section>

        {/* Our blog section */}

        <section className='open-account'>
        <div className='box-account'>
          <h1 className='account-h1'>Created by professionals. For professionals.</h1>
          <button className=' btn open-ac-btn'>Open Account ></button>
        </div>
      </section>

        </>
    );
};

export default AboutTest;
