import React from 'react';
import './Portfolio.css';

const PortfolioTest = () => {
  return (
    <div className="dashboard">
      <header className="header">
        <h1>Bullkit</h1>
        <nav className="nav">
          <a href="#dashboard">Dashboard</a>
          <a href="#market">Market</a>
          <a href="#portfolio">Portfolio</a>
          <a href="#position">Position</a>
          <a href="#orders">Orders</a>
          <a href="#funds">Funds</a>
        </nav>
        <div className="user-info">
          <p>Admin</p>
          <img src="user-avatar-url" alt="User Avatar" />
        </div>
      </header>

      <main className="main-content">
        <div className="left-column">
          <div className="funds-available">
            <h2>Fund Available</h2>
            <p>$2400.00</p>
            <button>Add Funds</button>
          </div>

          <div className="new-ipo">
            <h2>New IPO</h2>
            <div className="ipo-details">
              <img src="starbucks-logo-url" alt="Starbucks Logo" />
              <p>Starbucks</p>
              <p>Sep 02, 2021 to Sep 05, 2021</p>
              <p>Allotment: Sep 08, 2021</p>
              <button>Apply</button>
            </div>
          </div>
        </div>

        <div className="center-column">
          <h2>Nifty 50</h2>
          <div className="nifty-stats">
            <p>16,538.45 -78.00 (-0.49%)</p>
            {/* Here you would integrate a chart component */}
          </div>

          <div className="top-gainers">
            <h2>Top Gainers</h2>
            <table>
              <thead>
                <tr>
                  <th>Company Name</th>
                  <th>Stock Price</th>
                  <th>Change</th>
                  <th>% Change</th>
                  <th>Volume</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Apple Inc</td>
                  <td>$147.95</td>
                  <td>$1.60</td>
                  <td>1.5%</td>
                  <td>52,56,333</td>
                </tr>
                <tr>
                  <td>Tesla Inc</td>
                  <td>$680.95</td>
                  <td>$6.60</td>
                  <td>1.01%</td>
                  <td>56,254</td>
                </tr>
                <tr>
                  <td>Amazon.com, Inc.</td>
                  <td>$3199.95</td>
                  <td>$12.60</td>
                  <td>0.35%</td>
                  <td>72,654</td>
                </tr>
                <tr>
                  <td>Microsoft Corporation</td>
                  <td>$304.95</td>
                  <td>$7.60</td>
                  <td>2.56%</td>
                  <td>12,321</td>
                </tr>
                <tr>
                  <td>Dell Technologies Inc</td>
                  <td>$98.95</td>
                  <td>$2.28</td>
                  <td>2.35%</td>
                  <td>10,652</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="top-losers">
            <h2>Top Losers</h2>
            <table>
              <thead>
                <tr>
                  <th>Company Name</th>
                  <th>Stock Price</th>
                  <th>Change</th>
                  <th>% Change</th>
                  <th>Volume</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Apple Inc</td>
                  <td>$147.95</td>
                  <td>$1.60</td>
                  <td>1.5%</td>
                  <td>52,56,333</td>
                </tr>
                <tr>
                  <td>Tesla Inc</td>
                  <td>$680.95</td>
                  <td>$6.60</td>
                  <td>1.01%</td>
                  <td>56,254</td>
                </tr>
                <tr>
                  <td>Amazon.com, Inc.</td>
                  <td>$3199.95</td>
                  <td>$12.60</td>
                  <td>0.35%</td>
                  <td>72,654</td>
                </tr>
                <tr>
                  <td>Microsoft Corporation</td>
                  <td>$304.95</td>
                  <td>$7.60</td>
                  <td>2.56%</td>
                  <td>12,321</td>
                </tr>
                <tr>
                  <td>Dell Technologies Inc</td>
                  <td>$98.95</td>
                  <td>$2.28</td>
                  <td>2.35%</td>
                  <td>10,652</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="right-column">
          <div className="my-watchlist">
            <h2>My Watchlist</h2>
            <ul>
              <li>
                Apple Inc - $147.95 <span className="change">-110.60 (-0.52%)</span>
              </li>
              <li>
                Tesla Inc - $680.35 <span className="change">$10.50 (5.52%)</span>
              </li>
            </ul>
          </div>
        </div>
      </main>
    </div>
  );
};

export default PortfolioTest;
