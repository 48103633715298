import React from 'react'
import './ContactTest.css'
import { FaLocationDot } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { GiRotaryPhone } from "react-icons/gi";
import { FaFacebook } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";


const ContactTest = () => {
    return (
        <div>
            {/* Banner section */}
            <div className="relative w-full h-40 md:h-80 lg:h-50"> {/* Adjusted height */}
                <img src="https://wallpapers.com/images/hd/trading-wallpaper-ynfqhj74ml8p96ca.jpg" alt="Banner" className="object-cover w-full h-full md:h-96 lg:h-96" />
                {/*banner-content */}
                <div className="absolute top-0 left-5 lg:left-5 right-0 bottom-0 text-white flex flex-col justify-center ">
                    <div className="container mx-auto">
                        <h1 className="text-lg md:text-7xl font-semibold text-gray-100">Get In Touch</h1>

                        {/* <p className="mt-4 text-md md:text-xl font-semibold text-gray-100">Start Investing and Trading with Trading E’s Steps</p> */}
                        <ul class="d-flex mt-3">
                            <li class="text-xl font-bold" style={{ color: '#49cacd' }}>
                                <a href="#">HOME</a>
                            </li>
                            <p class="mx-4 text-xl font-bold" style={{ color: 'gold' }}>/</p>
                            <li class="mx-2 text-xl font-bold" style={{ color: '#49cacd' }}>
                                <a href="#">CONTACT</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            {/* Banner section */}
            {/* Map section start */}

            <section className="map-section-box p-4">
                <div className="row-container-box flex flex-wrap md:flex-nowrap">
                    {/* <!-- Left Column (Contact Information) --> */}
                    <div className="w-full md:w-5/12 p-4">
                        <h1 className="text-4xl md:text-5xl font-bold map-col-1-h1">Feel free to keep in touch with us</h1>
                        <p className="mt-4 text-sm md:text-base map-col-1-p">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis.</p>

                        {/* <!-- Office Information --> */}
                        <div className="flex items-start mt-6">
                            <div className="w-2/12 flex justify-center">
                                <FaLocationDot className="text-xl md:text-2xl icontStyle mt-2" />
                            </div>
                            <div className="w-10/12 pl-3">
                                <h2 className="text-lg font-bold">Our Office</h2>
                                <p className="text-sm md:text-base">Jln Cempaka Wangi No.22 <br /> Jakarta - Indonesia</p>
                            </div>
                        </div>

                        {/* <!-- Email Information --> */}
                        <div className="flex items-start mt-6">
                            <div className="w-2/12 flex justify-center">
                                <MdEmail className="text-xl md:text-2xl icontStyle mt-2" />
                            </div>
                            <div className="w-10/12 pl-3">
                                <h2 className="text-lg font-bold">Email</h2>
                                <p className="text-sm md:text-base">support@yourmail.tld <br /> hello@yourmail.tld</p>
                            </div>
                        </div>

                        {/* <!-- Phone Information --> */}
                        <div className="flex items-start mt-6">
                            <div className="w-2/12 flex justify-center">
                                <GiRotaryPhone className="text-xl md:text-2xl icontStyle mt-2" />
                            </div>
                            <div className="w-10/12 pl-3">
                                <h2 className="text-lg font-bold">Phone & Fax</h2>
                                <p className="text-sm md:text-base">+6221.2002.2012 <br /> +6221.2002.20123 (Fax)</p>
                            </div>
                        </div>
                    </div>

                    {/* <!-- Right Column (Map) --> */}
                    <div className="w-full md:w-7/12 mt-4 md:mt-0 p-2">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d317893.9737282887!2d-0.11951900000000001!3d51.503186!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487604b900d26973%3A0x4291f3172409ea92!2slastminute.com%20London%20Eye!5e0!3m2!1sen!2sus!4v1716185947077!5m2!1sen!2sus" className="w-full h-64 md:h-full"></iframe>
                    </div>
                </div>
            </section>
            {/* Map section end*/}

            {/* Contact form section start */}
            <section className='banner-Containar-box py-5'>
                <div className="form-containar-box pt-4">
                    <h1 className='text-center text-5xl font-bold'>Let's Talk</h1>
                    <p className='text-center pt-3 text-color'>Eu natoque est fusce felis erat vivamus habitant nunc urna ultricies.</p>
                    <hr className='mt-3 pt-1 ps-2' />
                    <form className='pt-3 ps-4'>
                        <div className="row mt-3">
                            <div className="col-4">
                                <label className='text-lg font-semibold text-color'>Name</label>
                                <input type="text" placeholder='Name' class="form-control" />
                            </div>
                            <div className="col-4">
                                <label className='text-lg font-semibold text-color'>Phone</label>
                                <input type="text" placeholder='Phone' class="form-control" />
                            </div>
                            <div className="col-4">
                                <label className='text-lg font-semibold text-color'>Email</label>
                                <input type="text" placeholder='Email' class="form-control" />
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-6">
                                <label className='text-lg font-semibold text-color'>Subject</label>
                                <input type="text" class="form-control" placeholder='Subject' />
                            </div>
                            <div className="col-6">
                                <label className='text-lg font-semibold text-color'>Company</label>
                                <input type="text" class="form-control" placeholder='Company' />
                            </div>
                        </div>
                        <div className='row mt-3 mr-2'>
                            <div className="col-12">
                                <label className='text-lg font-semibold text-color'>Message</label>
                                <textarea class="form-control mt-2" rows="5" placeholder='Message'></textarea>
                            </div>
                        </div>
                        <div className='d-flex justify-center'>
                            <button className='btn send-btn'>Send My Message</button>
                        </div>
                    </form>
                    <div className="social-media-box mt-4">
                        <div className='social-media-icon'>
                            <h1>Follow our social media</h1>
                            <div className='social-media-icon-style'>
                                <FaFacebook />
                                <FaTwitter />
                                <FaYoutube />
                                <FaInstagramSquare />
                                <FaLinkedin />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Contact form section end */}
        </div>
    )
}

export default ContactTest