import React, { useState } from 'react';
import './toplosers.css';

const data = [
  { company: 'Apple Inc', ticker: 'NASDAQ', price: 147.95, change: -1.60, percentChange: -1.5, volume: '52,56,333' },
  { company: 'Tesla Inc', ticker: 'NASDAQ', price: 680.95, change: -6.60, percentChange: -1.01, volume: '56,254' },
  { company: 'Amazon.com, Inc.', ticker: 'NASDAQ', price: 3199.95, change: -12.60, percentChange: -0.35, volume: '72,654' },
  { company: 'Microsoft Corporation', ticker: 'NASDAQ', price: 304.95, change: -7.60, percentChange: -2.56, volume: '12,321' },
  { company: 'Dell Technologies Inc', ticker: 'NYSE', price: 98.95, change: -2.28, percentChange: -2.35, volume: '10,652' },
];

const Toplosers = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const totalPages = Math.ceil(data.length / itemsPerPage);

  const handleClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderTableData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const selectedData = data.slice(startIndex, startIndex + itemsPerPage);
    return selectedData.map((item, index) => (
      <tr key={index}>
        <td>{item.company} <span className="ticker">{item.ticker}</span></td>
        <td>${item.price.toFixed(2)}</td>
        <td className="negative-change">${Math.abs(item.change).toFixed(2)}</td>
        <td className="negative-change">{Math.abs(item.percentChange).toFixed(2)}%</td>
        <td>{item.volume}</td>
      </tr>
    ));
  };

  return (
    <div className="toplosers-container">
      <div className="table-container-box">
        <h3>Top Losers</h3>
        <div className="table-responsive">
          <table>
            <thead>
              <tr>
                <th>Company Name</th>
                <th>Stock Price</th>
                <th>Change</th>
                <th>% Change</th>
                <th>Volume</th>
              </tr>
            </thead>
            <tbody>
              {renderTableData()}
            </tbody>
          </table>
        </div>
        <div className="pagination">
          <button onClick={() => handleClick(1)} disabled={currentPage === 1}>Previous</button>
          {[...Array(totalPages)].map((_, index) => (
            <button key={index} onClick={() => handleClick(index + 1)} className={currentPage === index + 1 ? 'active' : ''}>
              {index + 1}
            </button>
          ))}
          <button onClick={() => handleClick(totalPages)} disabled={currentPage === totalPages}>Next</button>
        </div>
      </div>
    </div>
  );
};

export default Toplosers;
