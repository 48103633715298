import React from "react";
import './ServiceTest.css'
import { GiElephant } from "react-icons/gi";
import { FaArrowRightLong } from "react-icons/fa6";
import { BsGraphUpArrow } from "react-icons/bs";
import { FaFileInvoiceDollar } from "react-icons/fa";
import { TbDatabaseDollar } from "react-icons/tb";
import { SiBitcoin } from "react-icons/si";
import { FaCommentsDollar } from "react-icons/fa6";


const ServiceTest = () => {
  return (
    <>
      {/* Banner-section */}
      <div className="relative w-full h-50 md:h-96 lg:h-70">
        <img src="https://png.pngtree.com/background/20230618/original/pngtree-smartphone-stock-trading-app-concept-in-3d-rendering-picture-image_3752860.jpg" alt="Banner" className="object-cover w-full h-64 md:h-96 lg:h-96" />
        {/*banner-content */}
        <div className="absolute top-0 left-5 lg:left-5 right-0 bottom-0 text-white flex flex-col justify-center ">
          <div className="container mx-auto">
            <h1 className="text-lg md:text-7xl font-semibold text-gray-100">What We Offer</h1>
            
            {/* <p className="mt-4 text-md md:text-xl font-semibold text-gray-100">Start Investing and Trading with Trading E’s Steps</p> */}
            <ul class="d-flex mt-3">
    <li class="text-xl font-bold" style={{color: '#49cacd'}}>
        <a href="#">HOME</a>
    </li>
    <p class="mx-4 text-xl font-bold" style={{color: 'gold'}}>/</p>
    <li class="mx-2 text-xl font-bold" style={{color: '#49cacd'}}>
        <a href="#">SERVICES</a>
    </li>
</ul>
          </div>
        </div>
      </div>

      {/* services-card */}
      <section className='our-service-contaner-box'>
        <div className='our-service'>
          <p className='our-p1'>OUR SERVICE PLAN</p><br />
          <h1 className='our-h1'>The Best Service We Offer</h1><br />
          <p className='our-p2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper <br /> mattis, pulvinar dapibus leo.</p>
        </div>
        <div className='card-box-6'>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            <div className="">
              <div className="card shadow IIst-card mx-auto" style={{ width: '100%', height: '18rem' }}>
                <div className="card-body">
                  <h5 className="card-title icons-style-1"><GiElephant /></h5>
                  <h6 className="card-subtitle mt-2 card-heading">Investing</h6>
                  <p className="card-text mt-2 card-para-1">Tellus litora curabitur consequat risus vestibulum purus leo tempus adipiscing vitae ut neque sem.</p>
                  <h5 className="card-title icon-style-arrow"><FaArrowRightLong /></h5>
                </div>
              </div>
            </div>
            <div className="">
              <div className="card shadow Ist-card mx-auto" style={{ width: '100%', height: '18rem' }}>
                <div className="card-body">
                  <h5 className="card-title icons-style-2 "><BsGraphUpArrow /></h5>
                  <h6 className="card-subtitle mt-2 card-heading-2">Trading</h6>
                  <p className="card-text mt-2 card-para-2">Tellus litora curabitur consequat risus vestibulum purus leo tempus adipiscing vitae ut neque sem.</p>
                  <h5 className="card-title icon-style-arrow"><FaArrowRightLong /></h5>
                </div>
              </div>
            </div>
            <div className="">
              <div className="card shadow IIst-card mx-auto" style={{ width: '100%', height: '18rem' }}>
                <div className="card-body">
                  <h5 className="card-title icons-style-1"><FaFileInvoiceDollar /></h5>
                  <h6 className="card-subtitle mt-2 card-heading">Mutual Fund</h6>
                  <p className="card-text mt-2 card-para-1">Tellus litora curabitur consequat risus vestibulum purus leo tempus adipiscing vitae ut neque sem.</p>
                  <h5 className="card-title icon-style-arrow"><FaArrowRightLong /></h5>
                </div>
              </div>
            </div>
            <div className="">
              <div className="card shadow IIst-card mx-auto" style={{ width: '100%', height: '18rem' }}>
                <div className="card-body">
                  <h5 className="card-title icons-style-1"><TbDatabaseDollar /></h5>
                  <h6 className="card-subtitle mt-2 card-heading">Wealth Management</h6>
                  <p className="card-text mt-2 card-para-1">Tellus litora curabitur consequat risus vestibulum purus leo tempus adipiscing vitae ut neque sem.</p>
                  <h5 className="card-title icon-style-arrow"><FaArrowRightLong /></h5>
                </div>
              </div>
            </div>
            <div className="">
              <div className="card shadow IIst-card mx-auto" style={{ width: '100%', height: '18rem' }}>
                <div className="card-body">
                  <h5 className="card-title icons-style-1"><SiBitcoin /></h5>
                  <h6 className="card-subtitle mt-2 card-heading">Crypto Investment</h6>
                  <p className="card-text mt-2 card-para-1">Tellus litora curabitur consequat risus vestibulum purus leo tempus adipiscing vitae ut neque sem.</p>
                  <h5 className="card-title icon-style-arrow"><FaArrowRightLong /></h5>
                </div>
              </div>
            </div>
            <div className="">
              <div className="card shadow IIst-card mx-auto" style={{ width: '100%', height: '18rem' }}>
                <div className="card-body">
                  <h5 className="card-title icons-style-1"><FaCommentsDollar /></h5>
                  <h6 className="card-subtitle mt-2 card-heading">Investment Advisory</h6>
                  <p className="card-text mt-2 card-para-1">Tellus litora curabitur consequat risus vestibulum purus leo tempus adipiscing vitae ut neque sem.</p>
                  <h5 className="card-title icon-style-arrow"><FaArrowRightLong /></h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* get up to cards */}
      <section className='special-offer-section'>
    <div className='special-offer-content'>
        <h1>Get up to $600 plus 60 days of commission-free stocks & forex trades</h1>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.</p>
        <button className='special-offer-btn btn btn-style'>Open Account ></button>
    </div>
</section>

{/* Why choose us section start */}
<section class="why-choose-us-box mt-10 md:mt-20 lg:mt-32">
        <div class="container mx-auto flex flex-col-reverse md:flex-row">
          <div class="md:w-1/2">
            <img class="w-full rounded-lg" src="https://media.istockphoto.com/id/1327069216/photo/decision-making-is-better-when-you-do-it-together.jpg?s=612x612&w=0&k=20&c=4D5etc3VrGI_CrjrZsm3k4Re0cNgUAKywcy-UPA3g6g=" alt="Decision Making Together" />
          </div>
          <div class="md:w-1/2 md:ml-10">
            <h1 class="text-xl md:text-2xl lg:text-xl font-semibold text-teal-400 mb-4">| WHY CHOOSE US</h1>
            <h2 class="text-2xl md:text-3xl font-bold mb-4">An investment that takes you to great heights</h2>
            <p class="mb-6">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.</p>
            <div class="flex mb-4">
              <div class="w-12 h-12 flex items-center justify-center rounded-md p-2 bg-teal-400 mr-4">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-white" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M16.293 3.293a1 1 0 0 1 1.414 1.414l-9 9a1 1 0 0 1-1.414 0l-5-5a1 1 0 1 1 1.414-1.414L7 11.086l8.293-8.293a1 1 0 0 1 1.414 0z" clip-rule="evenodd" />
                </svg>
              </div>
              <div>
                <h3 class="text-lg md:text-xl font-semibold mb-1">Trusted by Investor</h3>
                <p class="mb-4">Suscipit class etiam letius efficitur a hac accumsan si. Lacinia arcu dictumst a penatibus non fermentum donec sociosqu sed vulputate ante.</p>
              </div>
            </div>
            <div class="flex mb-4">
              <div class="w-12 h-12 flex items-center justify-center rounded-md p-2 bg-teal-400 mr-4">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-white" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M16.293 3.293a1 1 0 0 1 1.414 1.414l-9 9a1 1 0 0 1-1.414 0l-5-5a1 1 0 1 1 1.414-1.414L7 11.086l8.293-8.293a1 1 0 0 1 1.414 0z" clip-rule="evenodd" />
                </svg>
              </div>
              <div>
                <h3 class="text-lg md:text-xl font-semibold mb-1">Invest With Confidence</h3>
                <p class="mb-4">Suscipit class etiam letius efficitur a hac accumsan si. Lacinia arcu dictumst a penatibus non fermentum donec sociosqu sed vulputate ante.</p>
              </div>
            </div>
            <div class="flex">
              <div class="w-12 h-12 flex items-center justify-center  rounded-md p-2 bg-teal-400 mr-4">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-white" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M16.293 3.293a1 1 0 0 1 1.414 1.414l-9 9a1 1 0 0 1-1.414 0l-5-5a1 1 0 1 1 1.414-1.414L7 11.086l8.293-8.293a1 1 0 0 1 1.414 0z" clip-rule="evenodd" />
                </svg>
              </div>
              <div>
                <h3 class="text-lg md:text-xl font-semibold mb-1">Secure Platform</h3>
                <p class="mb-4">Suscipit class etiam letius efficitur a hac accumsan si. Lacinia arcu dictumst a penatibus non fermentum donec sociosqu sed vulputate ante.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
{/* Why choose us section end */}

{/* download app by play store start */}
<section className='download-App-box'>
        <div className="row">
          <div className="col-md-6 column-A1">
            <h1 className='download-h1'>Download <span>GetTrade</span> Apps</h1>
            <p className='download-p'>Ridiculus fusce maecenas ut blandit tempus. Lorem semper penatibus venenatis convallis efficitur. Placerat laoreet pellentesque aliquet habitant eleifend quis ridiculus dictumst habitasse.</p>
            <div className='d-flex'>
              <button className="">
                <img className='image-App-store' src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTcWqEUqjgkjjzT9p4nOKjZYxa2qeesoq-qyS9K6i5-gQ&s" alt="App Store" />
              </button>
              <button className="">
                <img className='image-play-store' src="https://www.paywise.com.au/wp-content/uploads/2022/10/Google-Play-App-Store-Button.png" alt="Play Store" />
              </button>
            </div>
          </div>
          <div className="col-md-6 column-A2">
            <img className='image-mobile-app' src="https://wallpapers.com/images/hd/mobile-stock-trading-app-screenshots-92f45o8day2fkd7k.jpg" alt="" />
          </div>
        </div>
      </section>
{/* download app by play store end */}

{/* textimoial section start */}
<section className="container">
      <div className='our-service testimonial-box-div'>
          <p className='our-p1'>TESTIMONIALS</p><br />
          <h1 className='our-h1'>Our Clients Reviews</h1><br />
          <p className='our-p2'>Ad vitae accumsan velit vivamus faucibus sapien ac augue bibendum porttitor.</p>
        </div>
        <div className="card-group my-5 gap-3">
          <div className="card  mb-4">
            <div className="card-body bg-slate-300">
              <p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This card has even longer content than the first to show that equal height action.</p>
            </div>
            <div className="row">
              <div className="col-md-4">
                <img className="card-img-top-style" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS1rSPF_JXuStwHIk_BmV-ti24icEt9oR4mhkhrUnPU8ZNVBlGUEVqzqgXWFtzNNgvBqJs&usqp=CAU" alt="Card image cap" />
              </div>
              <div className="col-md-8">
                <h1>Wilton Groves</h1>
                <p>Trader</p>
              </div>
            </div>
          </div>
          <div className="card border mb-4">
            <div className="card-body bg-slate-300">
              <p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This card has even longer content than the first to show that equal height action.</p>
            </div>
            <div className="row">
              <div className="col-md-4">
                <img className="card-img-top-style" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS1rSPF_JXuStwHIk_BmV-ti24icEt9oR4mhkhrUnPU8ZNVBlGUEVqzqgXWFtzNNgvBqJs&usqp=CAU" alt="Card image cap" />
              </div>
              <div className="col-md-8">
                <h1>Wilton Groves</h1>
                <p>Trader</p>
              </div>
            </div>
          </div>
          <div className="card mb-4">
            <div className="card-body bg-slate-300">
              <p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This card has even longer content than the first to show that equal height action.</p>
            </div>
            <div className="row">
              <div className="col-md-4">
                <img className="card-img-top-style" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS1rSPF_JXuStwHIk_BmV-ti24icEt9oR4mhkhrUnPU8ZNVBlGUEVqzqgXWFtzNNgvBqJs&usqp=CAU" alt="Card image cap" />
              </div>
              <div className="col-md-8">
                <h1>Wilton Groves</h1>
                <p>Trader</p>
              </div>
            </div>
          </div>
        </div>
      </section>
{/* textimoial section end*/}
{/* open account section btn */}
<section className='open-account'>
        <div className='box-account'>
          <h1 className='account-h1'>Created by professionals. For professionals.</h1>
          <button className=' btn open-ac-btn'>Open Account ></button>
        </div>
      </section>
{/* open account section btn */}

    </>
  );
};

export default ServiceTest;
