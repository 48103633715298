import React, { useState } from 'react';
// import { IoMdGrid, IoNotifications } from 'react-icons/io';
import { IoMdGrid } from "react-icons/io";
import { IoNotifications } from "react-icons/io5";
import { CgProfile } from "react-icons/cg";
import './dashboardTest.css';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import Watchlist from './watchlist';



const Dashboardtest = () => {
    const [isOpen, setIsOpen] = useState(false);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };
    const stocks = [
        { name: 'Apple INC', qty: 10, avgPrice: 144.50, cmpPrice: 147.70, valueAtCost: 1445.00, valueAtCMP: 1477.00, daysGain: 0.80, return: 32 },
        { name: 'Tesla INC', qty: 25, avgPrice: 444.50, cmpPrice: 485.70, valueAtCost: 14254.00, valueAtCMP: 16458.00, daysGain: 0.36, return: 52 },
        { name: 'Amazon INC', qty: 10, avgPrice: 144.50, cmpPrice: 147.70, valueAtCost: 1445.00, valueAtCMP: 1477.00, daysGain: 0.80, return: 32 },
        { name: 'Microsoft Corporation', qty: 25, avgPrice: 444.50, cmpPrice: 485.70, valueAtCost: 14254.00, valueAtCMP: 16458.00, daysGain: 0.36, return: 52 },
        { name: 'Dell Technologies', qty: 10, avgPrice: 144.50, cmpPrice: 147.70, valueAtCost: 1445.00, valueAtCMP: 1477.00, daysGain: 0.80, return: 32 },
        { name: 'Apple INC', qty: 25, avgPrice: 444.50, cmpPrice: 485.70, valueAtCost: 14254.00, valueAtCMP: 16458.00, daysGain: 0.36, return: 52 },
        { name: 'Tesla Inc', qty: 10, avgPrice: 144.50, cmpPrice: 147.70, valueAtCost: 1445.00, valueAtCMP: 1477.00, daysGain: 0.80, return: 32 },
        { name: 'Amazon', qty: 25, avgPrice: 444.50, cmpPrice: 485.70, valueAtCost: 14254.00, valueAtCMP: 16458.00, daysGain: 0.36, return: 52 }
    ];


    return (
        <>
            <nav className="navbar bg-white shadow-md p-4 md:p-6 lg:px-20 flex justify-between items-center">
                <div className="flex items-center justify-between w-full md:w-auto">
                    <div className="flex items-center">
                        <img src="logo.png" alt="Logo" className="h-6 mr-2" /> {/* Replace with your logo image */}
                        <span className="text-xl font-semibold">Bullkit</span>
                    </div>
                    <button className="md:hidden text-2xl ml-auto" onClick={handleToggle}>
                        ☰
                    </button>
                </div>
                <ul className={`nav-links flex-col md:flex-row md:flex items-center ${isOpen ? 'flex' : 'hidden'} md:flex`}>
                    <li className="md:ml-6 my-2 md:my-0">
                        <a href="/dashboards" className="text-gray-700">Dashboards</a>
                    </li>
                    <li className="md:ml-6 my-2 md:my-0">
                        <a href="/market" className="text-gray-700">Market</a>
                    </li>
                    <li className="md:ml-6 my-2 md:my-0">
                        <a href="/portfolio" className="text-gray-700">Portfolio</a>
                    </li>
                    <li className="md:ml-6 my-2 md:my-0">
                        <a href="/position" className="text-gray-700">Position</a>
                    </li>
                    <li className="md:ml-6 my-2 md:my-0 relative">
                        <a href="/orders" className="text-gray-700">Orders</a>
                        {/* <span className="notification-dot absolute top-0 right-0 bg-red-600 w-2.5 h-2.5 rounded-full"></span> */}
                    </li>
                    <li className="md:ml-6 my-2 md:my-0">
                        <a href="/funds" className="text-gray-700">Funds</a>
                    </li>
                </ul>
                <div className="navbar-right flex items-center ml-auto md:ml-0">
                    <div className="icon mr-4">
                        <IoMdGrid className="text-2xl md:text-4xl border rounded-full p-1 bg-gray-400" />
                    </div>
                    <div className="icon mr-4 relative">
                        <IoNotifications className="text-2xl md:text-4xl border rounded-full p-1 bg-gray-400" />
                    </div>
                    <div className="profile flex items-center">
                        <CgProfile className="text-2xl md:text-4xl border rounded-full p-1 bg-gray-400" />
                        <span className="admin font-semibold ml-2">Admin</span>
                    </div>
                </div>
            </nav>

            <div className="ticker-nav ">
                <div className="ticker">
                    <div className="index">
                        <p className="index-name">Nifty 50</p>
                        <span className="index-value">16,538.45</span>
                        <span className="index-change ">-78.00 (0.49%)</span>
                    </div>
                    <div className="index">
                        <p className="index-name">BSE Sensex</p>
                        <span className="index-value">57,578.76</span>
                        <span className="index-change ">-273.51 (0.52%)</span>
                    </div>
                </div>
                <div className="nav">
                    <ul className="nav-links">
                        <li><a href="/explore">Explore</a></li>
                        <li><a href="/investment">Investment</a></li>
                        <li><a href="/ipo">IPO</a></li>
                    </ul>
                </div>
            </div>

            <div className='row-b-x-traing'>
                <div className="">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="row">
                                <div className="portfolio">
                                    <div className="summary">
                                        <div className="summary-item">
                                            <h3>$58,451.25</h3>
                                            <p>Amount Invested</p>
                                        </div>
                                        <div className="summary-item">
                                            <h3>$71,235.50</h3>
                                            <p>Current Value</p>
                                        </div>
                                        <div className="summary-item">
                                            <h3>$2,254.25</h3>
                                            <p>Day's Gain</p>
                                        </div>
                                        <div className="summary-item">
                                            <h3>$13,542.15</h3>
                                            <p>Overall Gain</p>
                                        </div>
                                    </div>
                                    <div className="transaction-history">
                                        <h3>Transaction History</h3>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Stocks</th>
                                                    <th>Qty.</th>
                                                    <th>Avg.Price</th>
                                                    <th>CMP Price</th>
                                                    <th>Value at Cost</th>
                                                    <th>Value at CMP</th>
                                                    <th>Day's Gain</th>
                                                    <th>Return</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {stocks.map((stock, index) => (
                                                    <tr key={index}>
                                                        <td>{stock.name}</td>
                                                        <td>{stock.qty}</td>
                                                        <td>{stock.avgPrice}</td>
                                                        <td>{stock.cmpPrice}</td>
                                                        <td>{stock.valueAtCost}</td>
                                                        <td>{stock.valueAtCMP}</td>
                                                        <td>{stock.daysGain}</td>
                                                        <td>{stock.return}</td>
                                                        <td className='d-flex'>
                                                            <button className="buy">B</button>
                                                            <button className="sell">S</button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-lg-4 col-md-12">
                            <Watchlist />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Dashboardtest;
