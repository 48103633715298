import React from 'react'
import './HomeTest.css'
import { FaRegCirclePlay } from "react-icons/fa6";
import { AiOutlineGlobal } from "react-icons/ai";
import { FaSearchDollar } from "react-icons/fa";
import { BsFillFileBarGraphFill } from "react-icons/bs";
import { RiCustomerService2Fill } from "react-icons/ri";
import { FaArrowRightLong } from "react-icons/fa6";
import { GiElephant } from "react-icons/gi";
import { BsGraphUpArrow } from "react-icons/bs";
import { FaFileInvoiceDollar } from "react-icons/fa";
import { TbDatabaseDollar } from "react-icons/tb";
import { SiBitcoin } from "react-icons/si";
import { FaCommentsDollar } from "react-icons/fa6";
import { FaArrowAltCircleRight } from "react-icons/fa";
import { FaCheckCircle } from "react-icons/fa";
import { FaCheck } from "react-icons/fa";
import { RxCrossCircled } from "react-icons/rx";
import { IoIosCheckbox } from "react-icons/io";
import { MdOutlineMail } from "react-icons/md";
import { FaFacebook } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { CiYoutube } from "react-icons/ci";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa6";



const HomeTest = () => {
  
  
  return (
    <>
      <div className="background-image-home">
        <div className="content ">
          {/* <h1>Making Your <br/> 
        Worldwide <br/> Trading Easy</h1> */}
          <h1>Making Your</h1>
          <h1>Worldwide</h1>
          <h1>Trading Easy</h1>
          <p>Fringilla scelerisque interdum dignissim tristique convallis<br />
            accumsan facilisis aptent odio gravida. Phasellus quam <br />
            orci lacus sociosqu lorem.</p>
          <div className='d-flex both-btn'>
            <button className="btn btn-get-started">Get Started</button>
            <button className='d-flex btn-view-intro'><FaRegCirclePlay className='mx-3 mt-2' /> VIEW INTRO</button>
          </div>
        </div>
      </div>

      <div className='card_containere'>
        <div className='card-box grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4'>
          <div className="col-md-3">
            <div className="card shadow Ist-card mx-auto" style={{ width: '18rem', height: '17rem' }}>
              <div className="card-body">
                <h5 className="card-title card-title-1"><AiOutlineGlobal /></h5>
                <h6 className="card-subtitle mt-2 card-head-i">Wide Product Range</h6>
                <p className="card-text mt-2">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card shadow IIst-card mx-auto" style={{ width: '18rem', height: '17rem' }}>
              <div className="card-body">
                <h5 className="card-title card-title-2"><FaSearchDollar /></h5>
                <h6 className="card-subtitle card-head mt-2">Transparent Pricing</h6>
                <p className="card-text card-text-2 mt-2">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card shadow IIst-card mx-auto" style={{ width: '18rem', height: '17rem' }}>
              <div className="card-body">
                <h5 className="card-title card-title-2"><BsFillFileBarGraphFill /></h5>
                <h6 className="card-subtitle mt-2 card-head">Innovative <br /> Tools</h6>
                <p className="card-text card-text-2 mt-2">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card shadow IIst-card mx-auto" style={{ width: '18rem', height: '17rem' }}>
              <div className="card-body">
                <h5 className="card-title card-title-2"><RiCustomerService2Fill /> </h5>
                <h6 className="card-subtitle mt-2 card-head">Dedicated Support</h6>
                <p className="card-text card-text-2 mt-2">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
              </div>
            </div>
          </div>
        </div>
      </div>



      <section class=" mx-auto px-4 sm:px-6 lg:px-8 flex flex-wrap items-center justify-between who-we-are-box">
        <div class="w-full sm:w-1/2  sm:text-left">
          <div class="text-box">

            <h1 class="text-2xl sm:text-3xl lg:text-xl font-bold who-we-h1">
              <span>|</span> WHO WE ARE
            </h1> <br />
            <h1 class="text-3xl sm:text-4xl lg:text-5xl font-bold what-isgettrade">What is <span>GetTrade</span>?</h1>
            <p class="text-base sm:text-lg lg:text-xl para-p1">Malesuada vulputate pharetra natoque morbi id ex nec eleifend neque interdum. Cras vel scelerisque dis nisi magnis phasellus tempor. Nostra lobortis sagittis nulla finibus metus.</p>
            <p class="text-base sm:text-lg lg:text-xl para-p2">Dis laoreet pharetra fusce feugiat est habitant diam eros senectus rhoncus. Ad suscipit curabitur primis taciti dolor.</p>
            <button class="btn btn-Discover-more bg-teal-500 hover:bg-teal-600 text-white font-bold py-2 px-4 rounded mt-4 sm:mt-6">Discover More</button>
          </div>
        </div>
        <div class="w-full sm:w-1/2 mt-6 sm:mt-0">
          <img class="image-lapi w-full h-auto" src="https://kitnew.moxcreative.com/gettrade/wp-content/uploads/sites/8/2022/11/Ilus-3.png" alt="" />
        </div>
      </section>

      <section className="GetTrade-Section_box bg-white py-12">
      <div className="get-trade-sub-box w-full md:w-3/4 lg:w-2/3 xl:w-1/2 mx-auto p-4 ">
        <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold mb-4">
          <span className="text-teal-500">GetTrade</span> is trusted by 6+ million <br className="hidden md:block" /> users in 175+ countries
        </h1>
        <p className="text-base md:text-lg">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
        </p>

        <div className="testimonial-carousel mt-8 grid grid-cols-2 md:grid-cols-4 gap-4">
          <img src="https://kitnew.moxcreative.com/gettrade/wp-content/uploads/sites/8/2022/11/logoipsum-285.png" alt="" className="max-w-xs max-h-xs" />
          <img src="https://kitnew.moxcreative.com/gettrade/wp-content/uploads/sites/8/2022/11/logoipsum-264.png" alt="" className="max-w-xs max-h-xs" />
          <img src="https://kitnew.moxcreative.com/gettrade/wp-content/uploads/sites/8/2022/11/logoipsum-289.png" alt="" className="max-w-xs max-h-xs" />
          <img src="https://kitnew.moxcreative.com/gettrade/wp-content/uploads/sites/8/2022/11/logoipsum-236-1.png" alt="" className="max-w-xs max-h-xs" />
        </div>
      </div>
    </section>

    <section className='investments_that_Box'>
      <div className="flex flex-col sm:flex-row">
        <div className="sm:w-full md:w-1/2 md:pr-8">
          <h1 className="text-2xl sm:text-3xl lg:text-xl font-bold who-we-h1">
            <span>|</span> RELIABLE COMPANY
          </h1>
          <h1 className="text-3xl sm:text-4xl lg:text-5xl font-bold h2-heading mt-4">
            Investments that will <br /> make you grow
          </h1>
        </div>
        <div className="sm:w-full md:w-1/2 md:pl-8 mt-8 sm:mt-0">
          <p className="text-base sm:text-lg lg:text-base p-tag-color">
            Vehicula si vel phasellus iaculis interdum ex sociosqu id. Ullamcorper habitasse litora pharetra dictum mauris at vestibulum conubia placerat. Nostra odio fames sollicitudin nisl nulla fringilla enim. Inceptos felis maximus euismod venenatis purus mattis dignissim enim. Euismod ligula ullamcorper id vel velit scelerisque gravida. Blandit senectus platea tempus eros donec.
          </p>

          <p className="text-base sm:text-lg lg:text-base mt-4 p-tag-color">
            Viverra bibendum platea maximus conubia dictumst lorem hendrerit euismod. Nam pretium litora vestibulum amet magna ridiculus inceptos. Interdum erat fames arcu sodales volutpat nisl mollis tincidunt pellentesque porttitor. Sociosqu venenatis suspendisse nibh eros imperdiet scelerisque. Lacus consequat natoque fames quisque placerat molestie venenatis a consectetur interdum.
          </p>
        </div>
      </div>
    </section>

      <section className='our-service-contaner mt-5'>
        <div className='our-service'>
          <p className='our-p1'>OUR SERVICE PLAN</p><br />
          <h1 className='our-h1'>The Best Service We Offer</h1><br />
          <p className='our-p2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper <br /> mattis, pulvinar dapibus leo.</p>
        </div>
        <div className='card-box-6'>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            <div className="">
              <div className="card shadow IIst-card mx-auto" style={{ width: '100%', height: '18rem' }}>
                <div className="card-body">
                  <h5 className="card-title icons-style-1"><GiElephant /></h5>
                  <h6 className="card-subtitle mt-2 card-heading">Investing</h6>
                  <p className="card-text mt-2 card-para-1">Tellus litora curabitur consequat risus vestibulum purus leo tempus adipiscing vitae ut neque sem.</p>
                  <h5 className="card-title icon-style-arrow"><FaArrowRightLong /></h5>
                </div>
              </div>
            </div>
            <div className="">
              <div className="card shadow Ist-card mx-auto" style={{ width: '100%', height: '18rem' }}>
                <div className="card-body">
                  <h5 className="card-title icons-style-2 "><BsGraphUpArrow /></h5>
                  <h6 className="card-subtitle mt-2 card-heading-2">Trading</h6>
                  <p className="card-text mt-2 card-para-2">Tellus litora curabitur consequat risus vestibulum purus leo tempus adipiscing vitae ut neque sem.</p>
                  <h5 className="card-title icon-style-arrow"><FaArrowRightLong /></h5>
                </div>
              </div>
            </div>
            <div className="">
              <div className="card shadow IIst-card mx-auto" style={{ width: '100%', height: '18rem' }}>
                <div className="card-body">
                  <h5 className="card-title icons-style-1"><FaFileInvoiceDollar /></h5>
                  <h6 className="card-subtitle mt-2 card-heading">Mutual Fund</h6>
                  <p className="card-text mt-2 card-para-1">Tellus litora curabitur consequat risus vestibulum purus leo tempus adipiscing vitae ut neque sem.</p>
                  <h5 className="card-title icon-style-arrow"><FaArrowRightLong /></h5>
                </div>
              </div>
            </div>
            <div className="">
              <div className="card shadow IIst-card mx-auto" style={{ width: '100%', height: '18rem' }}>
                <div className="card-body">
                  <h5 className="card-title icons-style-1"><TbDatabaseDollar /></h5>
                  <h6 className="card-subtitle mt-2 card-heading">Wealth Management</h6>
                  <p className="card-text mt-2 card-para-1">Tellus litora curabitur consequat risus vestibulum purus leo tempus adipiscing vitae ut neque sem.</p>
                  <h5 className="card-title icon-style-arrow"><FaArrowRightLong /></h5>
                </div>
              </div>
            </div>
            <div className="">
              <div className="card shadow IIst-card mx-auto" style={{ width: '100%', height: '18rem' }}>
                <div className="card-body">
                  <h5 className="card-title icons-style-1"><SiBitcoin /></h5>
                  <h6 className="card-subtitle mt-2 card-heading">Crypto Investment</h6>
                  <p className="card-text mt-2 card-para-1">Tellus litora curabitur consequat risus vestibulum purus leo tempus adipiscing vitae ut neque sem.</p>
                  <h5 className="card-title icon-style-arrow"><FaArrowRightLong /></h5>
                </div>
              </div>
            </div>
            <div className="">
              <div className="card shadow IIst-card mx-auto" style={{ width: '100%', height: '18rem' }}>
                <div className="card-body">
                  <h5 className="card-title icons-style-1"><FaCommentsDollar /></h5>
                  <h6 className="card-subtitle mt-2 card-heading">Investment Advisory</h6>
                  <p className="card-text mt-2 card-para-1">Tellus litora curabitur consequat risus vestibulum purus leo tempus adipiscing vitae ut neque sem.</p>
                  <h5 className="card-title icon-style-arrow"><FaArrowRightLong /></h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='get-up-to'>
        <div className='get-up-600'>
          <h1>Get up to $600 plus 60  days of commission-free  stocks & forex trades</h1>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus,  luctus nec ullamcorper mattis, pulvinar dapibus leo.</p>
          <button className='btn btn-style '>Open Account > </button>
        </div>
      </section>

      <section className='our-subscription-contaner'>
        <div className='our-service'>
          <p className='our-p1'>PRICING PACKAGE</p><br />
          <h1 className='our-h1'>Choose the best plan that fit for you</h1><br />
          <p className='our-p2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec <br /> ullamcorper mattis, pulvinar dapibus leo.</p>
        </div>

        <div class='subscription-cards-box'>
          <div class='row subscription-cards'>
            <div class="col-md-4 mb-4">
              <div class="card rounded-xl" style={{ width: "24rem" }}>
                <div className="card rounded-xl m-auto mt-3 card-1" style={{ width: "22rem" }}>
                  {/* <img className="card-img-top" src="..." alt="Inner Card image cap" /> */}
                  <div className="card-body">
                    <h5 className="card-title text-center text-3xl font-bold white-color mt-3">Basic</h5>
                    <p className="card-text text-center font-bold black-color">Account</p>
                    <h5 className="card-title text-center font-bold text-3xl white-color mt-4">$<span className='text-7xl'>0</span>00 <br />
                      <span className='text-sm'>/Monthly</span>  </h5>

                    <div className='text-lg font-bold white-color mt-4' style={{ display: "flex", alignItems: "center" }}>
                      <FaCheckCircle style={{ marginRight: "0.5rem" }} />
                      <h5 className="card-title my-1 white-color ms-1">General investment account</h5>
                    </div>
                    <hr />
                    <div className='text-lg font-bold white-color mt-3' style={{ display: "flex", alignItems: "center" }}>
                      <RxCrossCircled style={{ marginRight: "0.5rem" }} />
                      <h5 className="card-title my-1 white-color ms-1"> Stocks and shares ISA</h5>
                    </div>
                    <hr />
                    <div className='text-lg font-bold white-color mt-3' style={{ display: "flex", alignItems: "center" }}>
                      <RxCrossCircled style={{ marginRight: "0.5rem" }} />
                      <h5 className="card-title my-1 white-color ms-1"> Self-invested personal pension</h5>
                    </div>
                    {/* <hr /> */}
                    <button className='btn buton-style'>Choose Plan</button>

                  </div>
                </div>
                <div class="card-body">
                  <h5 class="card-title text-xl font-bold ms-3 black-color">Benefit:</h5>
                  <div class='ms-3 black-color' style={{ display: "flex", alignItems: "center" }}>
                    <FaCheck style={{ marginRight: "0.5rem" }} />
                    <h5 class="card-title black-color">Automated order types</h5>
                  </div>
                  <div class='ms-3 black-color' style={{ display: "flex", alignItems: "center" }}>
                    <FaCheck style={{ marginRight: "0.5rem" }} />
                    <h5 class="card-title black-color">Advanced stock fundamentals</h5>
                  </div>
                  <div class='ms-3 black-color' style={{ display: "flex", alignItems: "center" }}>
                    <FaCheck style={{ marginRight: "0.5rem" }} />
                    <h5 class="card-title black-color">Fractional US Shares</h5>
                  </div>
                  <div class='ms-3 black-color' style={{ display: "flex", alignItems: "center" }}>
                    <FaCheck style={{ marginRight: "0.5rem" }} />
                    <h5 class="card-title black-color">Priority customer service</h5>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 mb-4">
              <div class="card rounded-xl" style={{ width: "24rem" }}>
                <div className="card rounded-xl m-auto mt-3 card-2" style={{ width: "22rem" }}>
                  {/* <img className="card-img-top" src="..." alt="Inner Card image cap" /> */}
                  <div className="card-body">
                    <h5 className="card-title text-center text-3xl font-bold white-color mt-3">Standatd</h5>
                    <p className="card-text text-center font-bold black-color">Account</p>
                    <h5 className="card-title text-center font-bold text-3xl white-color mt-4">$<span className='text-7xl'>19</span>00 <br />
                      <span className='text-sm'>/Monthly</span>  </h5>

                    <div className='text-lg font-bold white-color mt-4' style={{ display: "flex", alignItems: "center" }}>
                      <FaCheckCircle style={{ marginRight: "0.5rem" }} />
                      <h5 className="card-title my-1 white-color ms-1">General investment account</h5>
                    </div>
                    <hr />
                    <div className='text-lg font-bold white-color mt-3' style={{ display: "flex", alignItems: "center" }}>
                      <FaCheckCircle style={{ marginRight: "0.5rem" }} />
                      <h5 className="card-title my-1 white-color ms-1"> Stocks and shares ISA</h5>
                    </div>
                    <hr />
                    <div className='text-lg font-bold white-color mt-3' style={{ display: "flex", alignItems: "center" }}>
                      <RxCrossCircled style={{ marginRight: "0.5rem" }} />
                      <h5 className="card-title my-1 white-color ms-1"> Self-invested personal pension</h5>
                    </div>
                    {/* <hr /> */}
                    <button className='btn buton-style'>Choose Plan</button>

                  </div>
                </div>
                <div class="card-body">
                  <h5 class="card-title text-xl font-bold ms-3 black-color">Benefit:</h5>
                  <div class='ms-3 black-color' style={{ display: "flex", alignItems: "center" }}>
                    <FaCheck style={{ marginRight: "0.5rem" }} />
                    <h5 class="card-title black-color">Automated order types</h5>
                  </div>
                  <div class='ms-3 black-color' style={{ display: "flex", alignItems: "center" }}>
                    <FaCheck style={{ marginRight: "0.5rem" }} />
                    <h5 class="card-title black-color">Advanced stock fundamentals</h5>
                  </div>
                  <div class='ms-3 black-color' style={{ display: "flex", alignItems: "center" }}>
                    <FaCheck style={{ marginRight: "0.5rem" }} />
                    <h5 class="card-title black-color">Fractional US Shares</h5>
                  </div>
                  <div class='ms-3 black-color' style={{ display: "flex", alignItems: "center" }}>
                    <FaCheck style={{ marginRight: "0.5rem" }} />
                    <h5 class="card-title black-color">Priority customer service</h5>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 mb-4">
              <div class="card rounded-xl" style={{ width: "24rem" }}>
                <div className="card rounded-xl m-auto mt-3 card-1" style={{ width: "22rem" }}>
                  {/* <img className="card-img-top" src="..." alt="Inner Card image cap" /> */}
                  <div className="card-body">
                    <h5 className="card-title text-center text-3xl font-bold white-color mt-3">Plus</h5>
                    <p className="card-text text-center font-bold black-color">Account</p>
                    <h5 className="card-title text-center font-bold text-3xl white-color mt-4">$<span className='text-7xl'>49</span>00 <br />
                      <span className='text-sm'>/Monthly</span>  </h5>

                    <div className='text-lg font-bold white-color mt-4' style={{ display: "flex", alignItems: "center" }}>
                      <FaCheckCircle style={{ marginRight: "0.5rem" }} />
                      <h5 className="card-title my-1 white-color ms-1">General investment account</h5>
                    </div>
                    <hr />
                    <div className='text-lg font-bold white-color mt-3' style={{ display: "flex", alignItems: "center" }}>
                      <FaCheckCircle style={{ marginRight: "0.5rem" }} />
                      <h5 className="card-title my-1 white-color ms-1"> Stocks and shares ISA</h5>
                    </div>
                    <hr />
                    <div className='text-lg font-bold white-color mt-3' style={{ display: "flex", alignItems: "center" }}>
                      <FaCheckCircle style={{ marginRight: "0.5rem" }} />
                      <h5 className="card-title my-1 white-color ms-1"> Self-invested personal pension</h5>
                    </div>
                    {/* <hr /> */}
                    <button className='btn buton-style'>Choose Plan</button>

                  </div>
                </div>
                <div class="card-body">
                  <h5 class="card-title text-xl font-bold ms-3 black-color">Benefit:</h5>
                  <div class='ms-3 black-color' style={{ display: "flex", alignItems: "center" }}>
                    <FaCheck style={{ marginRight: "0.5rem" }} />
                    <h5 class="card-title black-color">Automated order types</h5>
                  </div>
                  <div class='ms-3 black-color' style={{ display: "flex", alignItems: "center" }}>
                    <FaCheck style={{ marginRight: "0.5rem" }} />
                    <h5 class="card-title black-color">Advanced stock fundamentals</h5>
                  </div>
                  <div class='ms-3 black-color' style={{ display: "flex", alignItems: "center" }}>
                    <FaCheck style={{ marginRight: "0.5rem" }} />
                    <h5 class="card-title black-color">Fractional US Shares</h5>
                  </div>
                  <div class='ms-3 black-color' style={{ display: "flex", alignItems: "center" }}>
                    <FaCheck style={{ marginRight: "0.5rem" }} />
                    <h5 class="card-title black-color">Priority customer service</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="why-choose-us-box mt-10 md:mt-20 lg:mt-32">
        <div class="container mx-auto flex flex-col-reverse md:flex-row">
          <div class="md:w-1/2">
            <img class="w-full rounded-lg" src="https://media.istockphoto.com/id/1327069216/photo/decision-making-is-better-when-you-do-it-together.jpg?s=612x612&w=0&k=20&c=4D5etc3VrGI_CrjrZsm3k4Re0cNgUAKywcy-UPA3g6g=" alt="Decision Making Together" />
          </div>
          <div class="md:w-1/2 md:ml-10">
            <h1 class="text-xl md:text-2xl lg:text-xl font-semibold text-teal-400 mb-4">| WHY CHOOSE US</h1>
            <h2 class="text-2xl md:text-3xl font-bold mb-4">An investment that takes you to great heights</h2>
            <p class="mb-6">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.</p>
            <div class="flex mb-4">
              <div class="w-12 h-12 flex items-center justify-center rounded-md p-2 bg-teal-400 mr-4">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-white" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M16.293 3.293a1 1 0 0 1 1.414 1.414l-9 9a1 1 0 0 1-1.414 0l-5-5a1 1 0 1 1 1.414-1.414L7 11.086l8.293-8.293a1 1 0 0 1 1.414 0z" clip-rule="evenodd" />
                </svg>
              </div>
              <div>
                <h3 class="text-lg md:text-xl font-semibold mb-1">Trusted by Investor</h3>
                <p class="mb-4">Suscipit class etiam letius efficitur a hac accumsan si. Lacinia arcu dictumst a penatibus non fermentum donec sociosqu sed vulputate ante.</p>
              </div>
            </div>
            <div class="flex mb-4">
              <div class="w-12 h-12 flex items-center justify-center rounded-md p-2 bg-teal-400 mr-4">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-white" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M16.293 3.293a1 1 0 0 1 1.414 1.414l-9 9a1 1 0 0 1-1.414 0l-5-5a1 1 0 1 1 1.414-1.414L7 11.086l8.293-8.293a1 1 0 0 1 1.414 0z" clip-rule="evenodd" />
                </svg>
              </div>
              <div>
                <h3 class="text-lg md:text-xl font-semibold mb-1">Invest With Confidence</h3>
                <p class="mb-4">Suscipit class etiam letius efficitur a hac accumsan si. Lacinia arcu dictumst a penatibus non fermentum donec sociosqu sed vulputate ante.</p>
              </div>
            </div>
            <div class="flex">
              <div class="w-12 h-12 flex items-center justify-center rounded-md p-2 bg-teal-400 mr-4">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-white" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M16.293 3.293a1 1 0 0 1 1.414 1.414l-9 9a1 1 0 0 1-1.414 0l-5-5a1 1 0 1 1 1.414-1.414L7 11.086l8.293-8.293a1 1 0 0 1 1.414 0z" clip-rule="evenodd" />
                </svg>
              </div>
              <div>
                <h3 class="text-lg md:text-xl font-semibold mb-1">Secure Platform</h3>
                <p class="mb-4">Suscipit class etiam letius efficitur a hac accumsan si. Lacinia arcu dictumst a penatibus non fermentum donec sociosqu sed vulputate ante.</p>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section className='download-App-box'>
        <div className="row">
          <div className="col-md-6 column-A1">
            <h1 className='download-h1'>Download <span>GetTrade</span> Apps</h1>
            <p className='download-p'>Ridiculus fusce maecenas ut blandit tempus. Lorem semper penatibus venenatis convallis efficitur. Placerat laoreet pellentesque aliquet habitant eleifend quis ridiculus dictumst habitasse.</p>
            <div className='d-flex'>
              <button className="">
                <img className='image-App-store' src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTcWqEUqjgkjjzT9p4nOKjZYxa2qeesoq-qyS9K6i5-gQ&s" alt="App Store" />
              </button>
              <button className="">
                <img className='image-play-store' src="https://www.paywise.com.au/wp-content/uploads/2022/10/Google-Play-App-Store-Button.png" alt="Play Store" />
              </button>
            </div>
          </div>
          <div className="col-md-6 column-A2">
            <img className='image-mobile-app' src="https://wallpapers.com/images/hd/mobile-stock-trading-app-screenshots-92f45o8day2fkd7k.jpg" alt="" />
          </div>
        </div>
      </section>

      <section className="container">
      <div className='our-service testimonial-box-div'>
          <p className='our-p1'>TESTIMONIALS</p><br />
          <h1 className='our-h1'>Our Clients Reviews</h1><br />
          <p className='our-p2'>Ad vitae accumsan velit vivamus faucibus sapien ac augue bibendum porttitor.</p>
        </div>
        <div className="card-group my-5 gap-3">
          <div className="card  mb-4">
            <div className="card-body bg-slate-300">
              <p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This card has even longer content than the first to show that equal height action.</p>
            </div>
            <div className="row">
              <div className="col-md-4">
                <img className="card-img-top-style" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS1rSPF_JXuStwHIk_BmV-ti24icEt9oR4mhkhrUnPU8ZNVBlGUEVqzqgXWFtzNNgvBqJs&usqp=CAU" alt="Card image cap" />
              </div>
              <div className="col-md-8">
                <h1>Wilton Groves</h1>
                <p>Trader</p>
              </div>
            </div>
          </div>
          <div className="card border mb-4">
            <div className="card-body bg-slate-300">
              <p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This card has even longer content than the first to show that equal height action.</p>
            </div>
            <div className="row">
              <div className="col-md-4">
                <img className="card-img-top-style" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS1rSPF_JXuStwHIk_BmV-ti24icEt9oR4mhkhrUnPU8ZNVBlGUEVqzqgXWFtzNNgvBqJs&usqp=CAU" alt="Card image cap" />
              </div>
              <div className="col-md-8">
                <h1>Wilton Groves</h1>
                <p>Trader</p>
              </div>
            </div>
          </div>
          <div className="card mb-4">
            <div className="card-body bg-slate-300">
              <p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This card has even longer content than the first to show that equal height action.</p>
            </div>
            <div className="row">
              <div className="col-md-4">
                <img className="card-img-top-style" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS1rSPF_JXuStwHIk_BmV-ti24icEt9oR4mhkhrUnPU8ZNVBlGUEVqzqgXWFtzNNgvBqJs&usqp=CAU" alt="Card image cap" />
              </div>
              <div className="col-md-8">
                <h1>Wilton Groves</h1>
                <p>Trader</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='our-blog-container'>
            <div className="col-lg-8 col-md-10 col-12 mx-auto blog-clm-B my-3">
              <h1 className="blog-clm-B-h1"> <span>|</span> FROM OUR BLOG</h1>
              <h1 className='blog-clm-B-h2'>Latest Article</h1>

              <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
                <div className="d-flex gap-3">
                  <div className="image-box-div">
                    <img src="https://media.istockphoto.com/id/1297832726/photo/portrait-of-a-smiling-young-businessman.jpg?s=612x612&w=0&k=20&c=32Qg7TnqfGkrDwTL3q0X0Kx9ab3JDzuqxzp4poH39zc=" alt="" />
                  </div>
                  <div className="col-7 inner-col_B">
                    <p className='p1'>Who In Their Right Mind Would Even Facebook’s New Libra Coin?</p>
                    <p className='p2'>October 31, 2022  No Comments</p>
                    <p className='p3'>
                      <a href="#">Read More »</a>
                    </p>
                  </div>
                </div>
                <div className="d-flex gap-3">
                  <div className="image-box-div">
                    <img src="https://media.istockphoto.com/id/1297832726/photo/portrait-of-a-smiling-young-businessman.jpg?s=612x612&w=0&k=20&c=32Qg7TnqfGkrDwTL3q0X0Kx9ab3JDzuqxzp4poH39zc=" alt="" />
                  </div>
                  <div className="col-7 inner-col_B">
                    <p className='p1'>Who In Their Right Mind Would Even Facebook’s New Libra Coin?</p>
                    <p className='p2'>October 31, 2022  No Comments</p>
                    <p className='p3'>
                      <a href="#">Read More »</a>
                    </p>
                  </div>
                </div>
                <div className="d-flex gap-3">
                  <div className="image-box-div">
                    <img src="https://media.istockphoto.com/id/1297832726/photo/portrait-of-a-smiling-young-businessman.jpg?s=612x612&w=0&k=20&c=32Qg7TnqfGkrDwTL3q0X0Kx9ab3JDzuqxzp4poH39zc=" alt="" />
                  </div>
                  <div className="col-7 inner-col_B">
                    <p className='p1'>Who In Their Right Mind Would Even Facebook’s New Libra Coin?</p>
                    <p className='p2'>October 31, 2022  No Comments</p>
                    <p className='p3'>
                      <a href="#">Read More »</a>
                    </p>
                  </div>
                </div>
                <div className="d-flex gap-3">
                  <div className="image-box-div">
                    <img src="https://media.istockphoto.com/id/1297832726/photo/portrait-of-a-smiling-young-businessman.jpg?s=612x612&w=0&k=20&c=32Qg7TnqfGkrDwTL3q0X0Kx9ab3JDzuqxzp4poH39zc=" alt="" />
                  </div>
                  <div className="col-7 inner-col_B">
                    <p className='p1'>Who In Their Right Mind Would Even Facebook’s New Libra Coin?</p>
                    <p className='p2'>October 31, 2022  No Comments</p>
                    <p className='p3'>
                      <a href="#">Read More »</a>
                    </p>
                  </div>
                </div>
                {/* Repeat similar structure for other blog posts */}
              </div>
            </div>
       
    </section>

      <section className='open-account'>
        <div className='box-account'>
          <h1 className='account-h1'>Created by professionals. For professionals.</h1>
          <button className=' btn open-ac-btn'>Open Account ></button>
        </div>
      </section>

      {/* <section className='footer-section bg-gray-900 text-white py-8'>
        <div className="container mx-auto">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8">
            <div className="footer-column">
              <h1 className='footer-h1'><span>|</span> Products</h1>
              <ul className='footer-ul'>
                <li>Saving Plans</li>
                <li>Stocks & ETFs</li>
                <li>Crypto</li>
                <li>Derivatives</li>
              </ul>
            </div>
            <div className="footer-column">
              <h1 className='footer-h1'><span>|</span> Company</h1>
              <ul className='footer-ul'>
                <li>About Us</li>
                <li>Blog</li>
                <li>Press Release</li>
                <li>Sitemap</li>
                <li>Contact Us</li>
              </ul>
            </div>
            <div className="footer-column">
              <h1 className='footer-h1'><span>|</span> Customers</h1>
              <ul className='footer-ul'>
                <li>Support</li>
                <li>Pricing Scheme</li>
                <li>Manage Cookies</li>
              </ul>
            </div>
            <div className="footer-column">
              <h1 className='footer-h1'><span>|</span> Get Trade</h1>
              <ul className='footer-ul'>
                <li>Jln Cempaka Wangi No 22, Jakarta - Indonesia</li>
                <li>support@yourmail.tld</li>
                <li>+6221.2002.2012</li>
              </ul>
            </div>
          </div>
          <ul className='flex mt-8'>
            <li className='icon-spacing'>Copyright © 2022 GetTrade, All rights reserved. Powered by MoxCreative</li>
            <li className='icon-spacing'>___________________________________________________________________</li>
            <li className='icon-spacing ms-3'><FaFacebook /></li>
            <li className='icon-spacing ms-3'><FaTwitter /></li>
            <li className='icon-spacing ms-3'><CiYoutube /></li>
            <li className='icon-spacing ms-3'><FaInstagram /></li>
            <li className='icon-spacing ms-3'><FaLinkedinIn /></li>
          </ul>
          <p className='footer-text  mt-8'>
            1) or fractional share
            Investing in the stock markets carries risk: the value of investments can go up as well as down and you may receive back less than your original investment. Individual investors should make their own decisions or seek independent advice.
          </p>
        </div>
      </section> */}

    </>
  )

}

export default HomeTest